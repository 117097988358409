import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout hasHeader={false} hasFooter={false}>
    <Seo title="404: Page Not Found" />

    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container is-max-desktop">
          <div className="columns">
            <div className="column">
              <h1 className="title c-hero-title is-1 pt-5">Page Not Found</h1>
              <p className="subtitle is-4 has-text-weight-light pt-3">
                The specified file was not found on this website. Please check
                the URL for mistakes and try again.
                <br />
                <br />
                <Link to="/">Go back home</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
